import "./src/style/global.scss";
import "./src/style/terminal-page/main.scss";

import { newtonsCradle, hourglass, jelly } from "ldrs";

newtonsCradle.register();
hourglass.register();
jelly.register();

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    registrations.forEach(registration => registration.unregister());
  });
}
